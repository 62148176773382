<template>
  <div
    :class="{
      'auth-form mx-auto mb-5 mt-3': true,
      'my-auto full-width relative': !allDataLoaded
    }">
    <div
      v-if="allDataLoaded"
      class="card mx-auto mb-5">
      <div class="card-body">
        <h1 class="heading-bigger text-center mb-4">
          Choose your membership plan
        </h1>
        <f-alert
          v-if="authError"
          theme="danger"
          visible
          class="mt-4 mb-5">
          <template v-if="currentErrors && Object.keys(currentErrors).length">
            <div>
              <template
                v-for="(errorKey) in Object.keys(currentErrors)">
                <div
                  v-for="(errorItem, errorItemIndex) in currentErrors[errorKey]"
                  :key="'error-' + errorKey + '-' + errorItemIndex"
                  class="mb-1 error-item">
                  {{ errorItem }}
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            Membership subscription error. Try again.
          </template>
        </f-alert>

        <validation-observer
          ref="membership-form"
          tag="form">

          <validation-provider
            rules="membership"
            name="membershipPackage"
            v-slot="{ errors }">

            <div class="membership-package-wrapper flex flex-wrap flex-justify-content-between">
              <div
                v-for="(item, index) in allPackages"
                :key="index"
                :class="{
                  'membership-package mt-2': true,
                  'membership-package-family': item.member_type === 'FAMILY',
                  'is-selected': item.id === membershipID
                }"
                @click.prevent="selectPackage(item.id)">
                <div class="heading-medium">{{ item.name }}</div>
                <div class="heading-tiny mt-2">£{{ item.value }} {{ getSubscribtionPeriod(item.valid_time) }}</div>
              </div>
            </div>

            <input
              v-model="membershipID"
              type="hidden"
              :state="errors.length ? 'invalid' : null" />
            <div class="invalid-feedback text-center">
              {{ errors[0] }}
            </div>

          </validation-provider>

          <div
            :class="{
              'membership-package membership-package-promotor mx-auto mt-2': true,
              'is-selected': becomePromoter
            }"
            @click.prevent="toggleIsPromoter">
            <div class="heading-medium">Promoter</div>
            <div class="heading-tiny mt-2">I would like to be a promoter</div>
          </div>

          <f-button
            type="submit"
            theme="primary"
            class="block mx-auto mt-4 text-uppercase"
            big
            @click.prevent="validateForm"
            :disabled="submitting">
            <template v-if="!submitting">Sign up</template>
            <template v-else>Signing up&hellip;</template>
          </f-button>

        </validation-observer>
      </div>
    </div>

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'RegisterMembership',
  computed: {
    allDataLoaded () {
      return this.packagesLoaded;
    }
  },
  data () {
    return {
      userID: 0,
      membershipID: null,
      becomePromoter: false,
      allPackages: [],
      packagesLoaded: false,
      loadError: false,
      submitting: false,
      authError: false
    }
  },
  mounted () {
    this.userID = this.$route.params.id;
    this.becomePromoter = !!this.$route.params.becomePromoter;
    this.loadAdditionalData();
  },
  methods: {
    submit () {
      this.authError = false;
      this.submitting = true;

      let formDataToSend = this.prepareFormDataToSend();

      FormUtils.submit(this, {
        endpoint: '/register/membership',
        formData: formDataToSend,
        successTitle: 'New membership purchase',
        successText: 'You will be now redirected to payment page.',
        errorTitle: 'An error has occurred',
        errorText: 'Membership subscription failed. Please try again.',
        submitShowInfo: true,
        successAction: (response) => {
          window.open(response.payment_checkout, '_self');
        },
        failAction: (error) => {
          console.log(error);
          this.currentErrors = error.response.data.errors;
          this.submitting = false;
          this.authError = true;
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['membership-form'], this.submit);
    },
    prepareFormDataToSend () {
      return {
        user_id: this.userID,
        membership_id: this.membershipID,
        is_promotor: +this.becomePromoter
      };
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/packages/list',
        method: 'get',
        outputKey: 'allPackages',
        loadedKey: 'packagesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });
    },
    getSubscribtionPeriod (monthCount) {
      if (monthCount === 1) {
        return 'monthly';
      }

      if (monthCount === 12) {
        return 'yearly';
      }

      if (monthCount === 3) {
        return 'quarterly';
      }

      return '';
    },
    selectPackage (selectedID) {
      Vue.set(this, 'membershipID', selectedID);
    },
    toggleIsPromoter () {
      Vue.set(this, 'becomePromoter', !this.becomePromoter);
    }
  }
}
</script>

<style scoped>
.error-item:last-child {
  margin-bottom: 0!important;
}
</style>
