var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'auth-form mx-auto mb-5 mt-3': true,
    'my-auto full-width relative': !_vm.allDataLoaded
  }},[(_vm.allDataLoaded)?_c('div',{staticClass:"card mx-auto mb-5"},[_c('div',{staticClass:"card-body"},[_c('h1',{staticClass:"heading-bigger text-center mb-4"},[_vm._v(" Choose your membership plan ")]),(_vm.authError)?_c('f-alert',{staticClass:"mt-4 mb-5",attrs:{"theme":"danger","visible":""}},[(_vm.currentErrors && Object.keys(_vm.currentErrors).length)?[_c('div',[_vm._l((Object.keys(_vm.currentErrors)),function(errorKey){return _vm._l((_vm.currentErrors[errorKey]),function(errorItem,errorItemIndex){return _c('div',{key:'error-' + errorKey + '-' + errorItemIndex,staticClass:"mb-1 error-item"},[_vm._v(" "+_vm._s(errorItem)+" ")])})})],2)]:[_vm._v(" Membership subscription error. Try again. ")]],2):_vm._e(),_c('validation-observer',{ref:"membership-form",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"rules":"membership","name":"membershipPackage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"membership-package-wrapper flex flex-wrap flex-justify-content-between"},_vm._l((_vm.allPackages),function(item,index){return _c('div',{key:index,class:{
                'membership-package mt-2': true,
                'membership-package-family': item.member_type === 'FAMILY',
                'is-selected': item.id === _vm.membershipID
              },on:{"click":function($event){$event.preventDefault();return _vm.selectPackage(item.id)}}},[_c('div',{staticClass:"heading-medium"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"heading-tiny mt-2"},[_vm._v("£"+_vm._s(item.value)+" "+_vm._s(_vm.getSubscribtionPeriod(item.valid_time)))])])}),0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.membershipID),expression:"membershipID"}],attrs:{"type":"hidden","state":errors.length ? 'invalid' : null},domProps:{"value":(_vm.membershipID)},on:{"input":function($event){if($event.target.composing){ return; }_vm.membershipID=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback text-center"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2664834951)}),_c('div',{class:{
            'membership-package membership-package-promotor mx-auto mt-2': true,
            'is-selected': _vm.becomePromoter
          },on:{"click":function($event){$event.preventDefault();return _vm.toggleIsPromoter.apply(null, arguments)}}},[_c('div',{staticClass:"heading-medium"},[_vm._v("Promoter")]),_c('div',{staticClass:"heading-tiny mt-2"},[_vm._v("I would like to be a promoter")])]),_c('f-button',{staticClass:"block mx-auto mt-4 text-uppercase",attrs:{"type":"submit","theme":"primary","big":"","disabled":_vm.submitting},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(!_vm.submitting)?[_vm._v("Sign up")]:[_vm._v("Signing up…")]],2)],1)],1)]):_vm._e(),(!_vm.allDataLoaded && !_vm.loadError)?_c('div',{staticClass:"loading-spinner-wrapper"},[_vm._m(0)]):_vm._e(),(_vm.loadError)?_c('f-alert',{staticClass:"my-5",attrs:{"visible":"","theme":"warning"}},[_c('span',[_vm._v(" Loading data failed. "),_c('a',{staticClass:"lnk lnk-alert",attrs:{"href":"javascript:window.location.reload();"}},[_vm._v(" Reload page ")]),_vm._v(" to try again. ")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-spinner pb-3 pt-3"},[_c('span',{staticClass:"loading-spinner-content"},[_vm._v(" Loading data… ")])])}]

export { render, staticRenderFns }